import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const url = "https://rest.techiepanda.in"
const CourseSlice = createSlice({
	name: "Courses",
	initialState: {
		value: [],
		Courses: [],

	},
	reducers: {},
	extraReducers: (builder) => {
	
		builder.addCase(getAllCourses.fulfilled, (state, action) => {
			state.Courses = action.payload;
		});
		
	},
});



export const getAllCourses = createAsyncThunk(
	"/getAllCourses",
	async (args) => {
		const token = localStorage.getItem("token");

		try {
			const response = await axios.get(`${url}/getAllCourses`, {
				headers: {
					Authorization: "Bearer " + token,
				},
			});

			return response.data;
		} catch (error) {
			console.log(error);
		}
	}
);

export default CourseSlice;
