import axios from 'axios'
import React, { useEffect } from 'react'
import StudentsStyles from './styles/student.module.scss'

const Contacted = () => {
    const [cData,setCData] = React.useState([])
    const contactUsData = async()=>{
        const data = await axios.get("https://server.techiepanda.in/contact/getAllContacted")
  
        setCData(data.data)
    }
  
    useEffect(()=>{
      contactUsData()
    },[])
  return (
    <div className={StudentsStyles.container}>
        <div className={StudentsStyles.headers}>
            <div>Student Name</div>
            <div>Student Phone</div>
            <div>Student E-mail</div>
            <div>Message</div>
        </div>
        <div className={StudentsStyles.body}>
            {
                cData && cData?.map((e,i)=>{
                    return(
                        <section key={i}>
                            <div>{e.name}</div>
                            <div>{e.phone}</div>
                            <div>{e.email}</div>
                            <div>{e.message ? e.message : "No Message"}</div>
                        </section>
                    )
                })
            }
            {
                !cData.length && <div>No Contacted Students</div>
            }
          
        </div>
    </div>
  )
}

export default Contacted