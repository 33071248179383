import {createSlice} from "@reduxjs/toolkit"

const HeaderComp = createSlice({
    name:"headerComp",
    initialState:{
        value:""
    },
    reducers :{
        ChangeCompVal :(state,action) => {
            state.value = action.payload
        }
    }
})

export const {ChangeCompVal} = HeaderComp.actions
export default HeaderComp