import React, { useEffect, useState } from 'react'
import BodyStyles from "./styles/body.module.scss" 
import { useDispatch, useSelector } from 'react-redux'
import { AddPayment } from '../../redux/slices/paymentLink'
import axios from 'axios'
import Students from './child/Students'
import Contacted from './child/contacted'

const Body = () => {
    const headerVals = useSelector((state) => state.headerVals.value)
    const courses = useSelector((state)=>state.courses.Courses)

    const paymenStatus = useSelector((state)=>state.payment.Status)

    const dispatch = useDispatch()

    const [SelectCourse,SetSelCOurse] = useState({})
    const [bodyVals ,setBodyVals] = useState({
        firstName : "",
        lastName :"",
        email:"",
        phone:"",
        amount:""
    })


    const TakeInVals = (e) => {

        setBodyVals({...bodyVals,[e.target.name]:e.target.value})
    }

    const SubmitButton = ()=>{
        const Splitted = SelectCourse.split("|")
        if(Splitted[0] == "Select One Course" ){
            alert("Select a course to send payment link")
        }
        else{
            dispatch(AddPayment({...bodyVals,SelectCourse:Splitted[0],id:Splitted[1]}))

        }
    }

   
  return (
    <div className={BodyStyles.bodyContainer}>

    
        {headerVals == "SPL" && (
            <div className={BodyStyles.SplContainer}>
                <div className={BodyStyles.SplTop}>
                    <div className={BodyStyles.bar}></div>
                    <div className={BodyStyles.SplGrid} >
                        <input placeholder='First Name' name='firstName' onChange={(e)=>TakeInVals(e)} />
                        <input placeholder='Last Name' name='lastName' onChange={(e)=>TakeInVals(e)} />
                        <input placeholder='Email' name='email' onChange={(e)=>TakeInVals(e)} />
                        <input placeholder='Phone' name='phone' type='number' onChange={(e)=>TakeInVals(e)}/>
                    </div>
                 
                        <div style={{textAlign:"center",padding:"2rem"}}>PASSWORD WILL BE SENT TO EMAIL CAN BE CHANGED IN DASHBOARD PROFILE SECTION</div>
                        <div className={BodyStyles.inPr}>
                            <input className={BodyStyles.PriceIn} placeholder='Price' name='amount' type='number' onChange={(e)=>TakeInVals(e)} />
                        </div>
                    <div className={BodyStyles.CourseSelector}>
                    <select  onChange={(f)=>SetSelCOurse(f.target.value)} >
                                <option className={BodyStyles.options} value={"Select One Course"} style={{backgroundColor:"orange"}}>{"Select One Course"}</option>  
                        {courses && courses.map((e,i)=>(
                   
                                <option className={BodyStyles.options} key={i} value={e.course_name + "|" + e._id} style={{backgroundColor:"orange"}}>{e.course_name}</option>
                            
                            ))}
                           
                    </select>
                    </div>
                </div>                
                <div className={BodyStyles.SplBottom}>
                    <button className={BodyStyles.button} onClick={SubmitButton}>Send Payment Link</button>
                </div>                
            </div>
        )
    }
        {headerVals == "CSI" && (
            <div className={BodyStyles.SplContainer}>
               <Contacted />
            </div>
        )
    }
     
        {headerVals == "ALS" && (
            <div className={BodyStyles.SplContainer}>
                <Students />
            </div>
        )
    }
    </div>
  )
}

export default Body