import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// const url = "http://localhost:1003"
// const url = "https://testpay.techeipanda.com"
const url = "https://payment.techiepanda.in"
// const url = "https://rest.techiepanda.in"

const PaymentSlice = createSlice({
	name: "Payment",
	initialState: {
		value: [],
		Status: "",

	},
	reducers: {},
	extraReducers: (builder) => {
	
		builder.addCase(AddPayment.fulfilled, (state, action) => {
			state.Status = action.payload;
		});
		
	},
});



export const AddPayment = createAsyncThunk(
	"/payment_links",
	async (args) => {
        const token = localStorage.getItem("token");
        
		try {
			const response = await axios.post(`${url}/payment_links`, {
                name: args.firstName + " " + args.lastName,
                email:args.email,
                phone:"+91"+args.phone,
                amount:args.amount,
                id:args.id,
                courseName:args.SelectCourse,
                createdAt:new Date().toString()
            },{
				headers: {
					Authorization: "Bearer " + token,
				},
			}).then((resp)=>{
                console.log(resp)
                if(resp?.data == "Student already enrolled this course") alert("Student already enrolled this course")
                if(resp?.data == "paid" || resp?.data == "success") alert("Payment Link Sent")
            } )

			return response.data;
		} catch (error) {
			console.log(error);
		}
	}
);

export default PaymentSlice;
