// import { Box, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import LoginStyles from"./login.module.scss"
// import { Visibility, VisibilityOff } from '@mui/icons-material'

export const Login = () => {

    const loginButon = async () => {
       await axios.post("https://rest.techiepanda.in/loginAdmin",{...creds}).then((res)=>{

            localStorage.setItem("token" , res.data.token)
            localStorage.setItem("type" , res.data.userType)
            window.location.reload()
        })


      }

    const [creds,setCreds] = useState({
        email:"",
        password:""
    })

    const onChanges = (event )=>{
        const target = event.target 

        setCreds({...creds,[target.name]:target.value})
    }

      const [showPassword, setShowPassword] = React.useState(false);

      const handleClickShowPassword = () => {
        if(showPassword == true) setShowPassword(false)
        else setShowPassword(true)
    };
 
  return (
    <div className={LoginStyles.body}>
         <img className={LoginStyles.wave} src="https://res.cloudinary.com/cliqtick/image/upload/v1689072186/icons/wave_kzmow3.png" />
 <div className={LoginStyles.container}>
  <div className={LoginStyles.img}>
   <img src="https://res.cloudinary.com/cliqtick/image/upload/v1689072186/icons/bg_ysnh25.png"/>
  </div>
  <div className={LoginStyles.loginContent}>

    <div className={LoginStyles.form}>

        <h2 className={LoginStyles.title}>Welcome</h2>
            <img src="https://res.cloudinary.com/cliqtick/image/upload/v1689072186/icons/avatar_e9hrqt.png"/>
                <div className={LoginStyles.inputDiv}>
                    <div className={LoginStyles.i}>
                    <i className={LoginStyles.faUser}></i>
                    </div>

                    <div>
                        <input placeholder="Email" name='email' onChange={(e)=>onChanges(e)} className={LoginStyles.input} />
                    </div>


                </div>
                <div className={LoginStyles.inputDiv}>
                    <input className={LoginStyles.input} name='password' placeholder='Password' type={showPassword ? "text" : "password"} onChange={(e)=>onChanges(e)} />
                    <div style={{marginTop:"2rem",cursor:"pointer"}} onClick={handleClickShowPassword}>showPassword</div>
                </div>

                <button className={LoginStyles.btn} onClick={loginButon}>Login</button>


    </div>

        </div>
    </div>
    </div>
  )
}
