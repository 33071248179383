import Header from "./components/utils/header";
import AppStyles from "./app.module.scss"
import {Routes,Route} from "react-router-dom"
import Body from "./components/parent/body";
import { useDispatch } from "react-redux";
import { getAllCourses } from "./redux/slices/course";
import { useEffect } from "react";
import { Login } from "./components/loginPages/login";

function App() {
  const dispatch = useDispatch()
  const token =localStorage.getItem("token")

  useEffect(()=>{
    dispatch(getAllCourses())
  },[])
  return (
    <div className={AppStyles.container}>
       {token && <Header />}
        <Routes>
           {token && <Route path="/" element={<Body />} />}
           {!token && <Route path="/" element={<Login />} />}
        </Routes>
    </div>
  );
}

export default App;
