import { configureStore } from "@reduxjs/toolkit";
import HeaderComp from "./slices/headerComp";
import CourseSlice from "./slices/course";
import PaymentSlice from "./slices/paymentLink";

const store = configureStore({
    reducer :{
        headerVals :  HeaderComp.reducer,
        courses : CourseSlice.reducer,
        payment : PaymentSlice.reducer
    }
})

export default store