import React, { useEffect, useState } from 'react';
import StudentsStyles from './styles/student.module.scss'
import axios from 'axios';

const Students = () => {
    const [students,setStudents] = useState("")
    const GetStudentsFun = async () =>{
        const {data} = await axios.get("https://rest.techiepanda.in/getAllStudents")
 
        setStudents(data)
    }

    useEffect(()=>{
        GetStudentsFun()
    },[])
  return (
    <div className={StudentsStyles.container}>
        <div className={StudentsStyles.headers}>
            <div>S.No</div>
            <div>Student Name</div>
            <div>Student Phone</div>
            <div>Student E-mail</div>
            <div>Enrolled</div>
        </div>
        <div className={StudentsStyles.body}>
            {
                students && students?.map((e,i)=>{
                    return(
                        <section key={i}>
                            <div>{i + 1}</div>
                            <div>{e.firstName +" " + e.lastName}</div>
                            <div>{e.phone}</div>
                            <div>{e.email}</div>
                            <div>{e.course ? "Enrolled to a course" : "Not enrolled to any course"}</div>
                        </section>
                    )
                })
            }
          
        </div>
    </div>
  )
}

export default Students