import React, { useEffect } from 'react'
import headerStyles from "./styles/header.module.scss"
import {useDispatch, useSelector} from "react-redux"
import { ChangeCompVal } from '../../redux/slices/headerComp'
const Header = () => {

  const headerVals = useSelector((state) => state.headerVals.value)
  const dispatch = useDispatch()
      const ChangeCompVals = (param) =>{
        dispatch(ChangeCompVal(param))
      }

      const logoutFun = () =>{
        localStorage.clear()
        window.location.reload()
      }
      useEffect(()=>{
        dispatch(ChangeCompVal("SPL"))
      },[])
  return (
    <div className={headerStyles.containerH}>
        <div className={headerStyles.logo}>
            <img src='https://res.cloudinary.com/cliqtick/image/upload/v1692600339/icons/logo-techie-_IE_uqk1bc.png' />
        </div>
        <div className={headerStyles.remaining}>
            <div onClick={()=>ChangeCompVals("SPL")} style={headerVals == "SPL" ?  {color:"black",boxShadow:"2px 2px 10px rgba(0, 0, 0, 0.451)",background:"white"} :null}>Send Payment Links</div>
            <div onClick={()=>ChangeCompVals("CSI")} style={headerVals == "CSI" ?  {color:"black",boxShadow:"2px 2px 10px rgba(0, 0, 0, 0.451)",background:"white"}:null}>Contacted Students Info</div>
            <div onClick={()=>ChangeCompVals("ALS")} style={headerVals == "ALS" ?  {color:"black",boxShadow:"2px 2px 10px rgba(0, 0, 0, 0.451)",background:"white"} :null}>All Students</div>
            <div onClick={logoutFun} style={{color:"black",boxShadow:"2px 2px 10px rgba(0, 0, 0, 0.451)",background:"white"}}>Logout</div>
        </div>
    </div>
  )
}

export default Header